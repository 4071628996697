import React, {useEffect, useState} from "react";

import axios from "axios";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import {Col, Container, Row} from "react-bootstrap";
import {FormSize, FormType} from "../SubmitForm";
import {UMRLICE_LARAVEL_API_TOKEN} from "../../config";

const Payment = () => {
    const [transactionDetails, setTransactionDetails] = useState(null);
    const [timeStamp, setTimeStamp] = useState(null);
    useEffect(() => {
        axios.get(`https://umrlice-vijesti-me.com/api/${UMRLICE_LARAVEL_API_TOKEN}/cart/get?user_id=${localStorage.getItem('userId')}`)
            .then(res => {
                if (res.data.data == null) {
                    window.location.href = '/predaj-citulju';
                }

                axios.get(`https://umrlice-vijesti-me.com/api/${UMRLICE_LARAVEL_API_TOKEN}/payment/last?user_id=${localStorage.getItem('userId')}`)
                    .then(res => {
                        setTransactionDetails(res.data);

                        NotificationManager.success(`Oglas uspješno dodat! Bićete obaviješteni kada admin potvrdi`);
                    });
                axios.get(`https://umrlice-vijesti-me.com/api/${UMRLICE_LARAVEL_API_TOKEN}/cart/empty-fields?user_id=${localStorage.getItem('userId')}`).then(res => {});

                setTimeout(function ()  {
                    window.location.href = '/';
                }, 3000);

                /*const formData = new FormData();

                let postInformation = res.data.data;

                formData.append("note", postInformation.note);
                formData.append("phone_number", postInformation.phone_number);

                formData.append("size", postInformation.size);
                formData.append("name", postInformation.name);

                formData.append("main_text", postInformation.main_text);
                formData.append("city", postInformation.city);
                formData.append("images", postInformation.images);
                formData.append("icon", postInformation.icon);
                formData.append("type", postInformation.type);
                formData.append("checkbox", postInformation.checkbox);
                formData.append("birth_date", postInformation.birth_date);
                formData.append("death_date", postInformation.death_date);
                formData.append("header_text", postInformation.header_text);
                formData.append("text_top", postInformation.text_top);
                formData.append("text_bottom", postInformation.bottom_text);
                formData.append("color", postInformation.color);
                formData.append("main_text", postInformation.main_text);

                axios.post(
                    "https://api.umrlice.vijesti.me/api/posts/",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                    }
                ).then(res => {
                    console.log({resPostit: res})
                    axios.get(`https://umrlice-vijesti-me.com/api/e6dfcdd0d0e693508e7f14ad1841cfe8/payment/set-post?user_id=${localStorage.getItem('userId')}&post_id=${res.data.id}`).then(r => {});

                    NotificationManager.success(
                        `- Plaćanje uspješno. Dobićete potvrdu putem maila kad objava bude na sajtu!`
                    );
                }).catch(err => {
                    /!*NotificationManager.success(
                        `Something went wrong post has not been added!`
                    );*!/
                    console.log({addingPostErr: err})
                });

                axios.get(`https://umrlice-vijesti-me.com/api/${UMRLICE_LARAVEL_API_TOKEN}/cart/empty-fields?user_id=${localStorage.getItem('userId')}`).then(res => {});*/
            })
            .catch(err => {
                console.log("smth went wrong", err);
            });
    }, []);
    return (
        <Container
            className="my-5"
            style={{paddingBottom: "6.5%", paddingTop: "100px"}}
        >
            <Row className="justify-content-center text-center">
                {transactionDetails && (
                    <div>
                        <span>Status: <b>{transactionDetails.transactionStatus}</b></span>
                        <br />
                        <span>Order ID: <b>{transactionDetails.purchaseId}</b></span>
                        <br />
                        <span>Amount: <b>{transactionDetails.amount + ' ' + transactionDetails.currency}</b></span>
                        <br />
                        <span>Card Type: <b>{transactionDetails.returnData.binBrand + ' ****' + transactionDetails.returnData.lastFourDigits}</b></span>
                        <br />
                        <span>Bank Code: <b>{transactionDetails?.extradata?.autCode}</b></span>
                        <br />
                        <span>Timestamp: <b>{transactionDetails.timestamp} UTC</b></span>
                    </div>
                )}
            </Row>

            <NotificationContainer animation={false}/>

        </Container>
    );
};

export default Payment;
