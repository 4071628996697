import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles

import { shortenString } from "../utils";
import ShareButtons from "./ShareButtons";

import { API_URL } from "../config";
import { isSerbianCyrillic } from "../submitFormUtils/isSerbianCyrlic";

function MediumPomen({ post, modalIsOpen, setModalIsOpen }) {
  const userRole = localStorage.getItem("userRole");
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const [counter, setCounter] = useState(post.counter);

  const handleCandleClick = async () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }

    try {
      await increaseCounter();
    } catch (error) {
      console.log("Error updating counter:", error);
    }
  };

  const increaseCounter = async () => {
    try {
      await axios.post(`${API_URL}/api/posts/${post.id}/counter/up/`, {});
      setCounter(post.counter + 1);
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(`${API_URL}/api/posts/${post.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const modalStyle = {
    border: "7px double black",
    backgroundColor: "white",
    height: "650px",
    marginTop: "20px",
  };
  const mainAndBottomTextLength =
    (post.main_text?.length || 0) + (post.text_bottom?.length || 0);

  // If the combined text length is less than or equal to 350, increase font size and padding
  const shouldIncreaseSize = mainAndBottomTextLength <= 330;

  const isSmallScreen = window.innerWidth <= 1024;
  return (
    <Container
      data-aos="fade-up"
      style={modalIsOpen ? { ...modalStyle, height: "auto" } : modalStyle}
      className="post"
    >
      {" "}
      <Row>
        <p
          style={{
            fontStyle: "italic",
            border: "none",
            borderTop: "none",
            textAlign: "right",
            backgroundColor: "white",
            color: "gray",
            fontSize: "smaller",
            paddingRight: "0.5vh",
          }}
        >
          {isSerbianCyrillic(post.name) ? "помен" : post.type}
        </p>
      </Row>
      <Col
        style={{ marginTop: shouldIncreaseSize ? "30px" : "0px" }}
        className="d-flex justify-content-center"
      >
        {post.icon !== "Bez ikonice" && (
          <img
            id="umrlica-icon"
            style={{
              height: "50px",
              width: "50px",
              border: "none",
            }}
            src={post.icon}
            alt=""
          />
        )}
      </Col>
      <Row>
        <p
          style={{
            padding: "2%",
            textAlign: "center",
            fontSize: shouldIncreaseSize ? "15px" : "12px",
          }}
        >
          {post.text_top}
        </p>
      </Row>
      <Row style={{ paddingTop: "0%" }}>
        <Col className="d-flex justify-content-around"></Col>
        {post.is_slider === true && ( //FIKSUJ PRIKAZ
          <Col className="d-flex justify-content-around">
            {post.images.length !== 0 && (
              <Carousel
                showThumbs={false}
                showArrows={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={2500}
              >
                {post.images
                  .reduce((p, c) => p + c)
                  .split(",")
                  .map((imagePath, index) => (
                    <div className="d-flex justify-content-center" key={index}>
                      <div
                        style={{
                          width: "120px",
                          height: "150px",
                          border: "3px solid black",
                          background: `url(${API_URL}/${imagePath})`,
                          backgroundSize: "cover",
                          backgroundPosition: "50% 50%",
                        }}
                      ></div>
                    </div>
                  ))}
              </Carousel>
            )}

            {post.images.length === 0 && (
              <div
                style={{
                  width: "150px",
                  height: "150px",
                }}
              ></div>
            )}
          </Col>
        )}{" "}
        {post.is_slider === false &&
          post.images.reduce((p, c) => p + c).split(",").length > 1 && (
            <Row>
              <Col
                className="d-flex justify-content-center"
                style={{ gap: "15%" }} // Adjust the gap value as needed
              >
                <div
                  style={{
                    width: "120px",
                    height: "150px",
                    border: "3px solid black",
                    background: `url(${API_URL}/${
                      post.images.reduce((p, c) => p + c).split(",")[0]
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                  }}
                  alt=""
                ></div>
                <div
                  style={{
                    width: "120px",
                    height: "150px",
                    border: "3px solid black",
                    background: `url(${API_URL}/${
                      post.images.reduce((p, c) => p + c).split(",")[1]
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                  }}
                  alt=""
                ></div>
              </Col>
            </Row>
          )}
        {post.images.reduce((p, c) => p + c).split(",").length === 1 && (
          <Row>
            <Col className="d-flex justify-content-center">
              <div
                style={{
                  width: "120px",
                  height: "150px",
                  border: "3px solid black",
                  background: `url(${API_URL}/${post.images
                    .reduce((p, c) => p + c)
                    .split(",")})`,
                  backgroundSize: "cover",
                  backgroundPosition: "50% 50%",
                }}
                src={``}
                alt=""
              ></div>
            </Col>
          </Row>
        )}
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <Row style={{ paddingTop: "2%" }}>
        <Col className="d-flex justify-content-around"></Col>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: shouldIncreaseSize ? "18px" : "16px",
          }}
        >
          {post.name.includes("\n")
            ? post.name
                .split("\n")
                .slice(0, 2)
                .map((line, index, array) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < array.length - 1 && <br />}
                  </React.Fragment>
                ))
            : post.name}
        </p>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      {modalIsOpen && (
        <Row className="md-pomen-main-text">
          <div
            className="main-text"
            style={{
              paddingLeft: "4%",
              paddingRight: "4%",
              textAlign: "left",
            }}
          >
            {modalIsOpen && (
              <Row className="md-pomen-main-text">
                <div
                  className="main-text"
                  style={{
                    padding: "1%",
                    paddingLeft: "4%",
                    paddingRight: "4%",
                    textAlign: "left",
                  }}
                >
                  {post.main_text.split("\n").map((s, index) => (
                    <p key={index} className="bottom-text-line">
                      {s.trim()}
                    </p>
                  ))}
                </div>
              </Row>
            )}
          </div>
        </Row>
      )}
      {!modalIsOpen && (
        <Row className="md-pomen-main-text">
          <p
            className="main-text"
            style={{
              paddingLeft: shouldIncreaseSize ? "4%" : "2%",
              paddingRight: shouldIncreaseSize ? "4%" : "2%",
              textAlign: "left",
              lineHeight: shouldIncreaseSize ? "" : " 1.2",

              fontSize: shouldIncreaseSize ? "15px" : "12px",
            }}
          >
            <span className="main-text" style={{ textAlign: "left" }}>
              {post.main_text.includes("\n")
                ? post.main_text
                    .split("\n")
                    .slice(0, 3)
                    .map((line, index, array) => (
                      <React.Fragment key={index}>
                        {shortenString(line, 150)}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))
                : isSmallScreen
                ? shortenString(post.main_text, 150)
                : post.main_text}
              {post.main_text.split("\n").length > 2 && "..."}
            </span>
          </p>
        </Row>
      )}
      {!modalIsOpen && (
        <Row>
          <b>
            <p
              style={{
                float: "right",
                fontSize: shouldIncreaseSize ? "15px" : " 12px",
                lineHeight: shouldIncreaseSize ? "" : " 1.2",
              }}
              id="sakrij"
            >
              {isSmallScreen
                ? shortenString(post.text_bottom, 65)
                : post.text_bottom}
            </p>
          </b>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ padding: "1%" }}>
          <b>
            <div style={{ float: "right" }} id="sakrij">
              {post.text_bottom.split("\n").map((s, index) => (
                <p key={index} className="bottom-text-line">
                  {s.trim()}
                </p>
              ))}
            </div>
          </b>
        </Row>
      )}
      {modalIsOpen && (userRole === "superadmin" || userRole === "admin") && (
        <Col>
          <button
            style={{ marginLeft: "3%" }}
            className="btn btn-danger"
            onClick={() => deletePosts()}
          >
            Obriši
          </button>
        </Col>
      )}
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default MediumPomen;
