import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import Umrlice from "./components/Umrlice";
import PredajCitulju from "./components/PredajCitulju";
import RegisterPage from "./components/RegisterPage";
import PoslednjiPozdravi from "./components/PoslednjiPozdravi";
import Pomeni from "./components/Pomeni";
import Footer from "./components/Footer";
import Login from "./components/Login";
import UserProfile from "./components/UserProfile";
import FormaZaPopunjavanje from "./components/FormaZaPopunjavanje";
import RedirectForm from "./components/RedirectForm";
import { SubmitForm, FormSize, FormType } from "./components/SubmitForm";
import SinglePost from "./components/SinglePost";
import UpdateForm from "./components/UpdateForm";
import axios from "axios";
import Tutorial from "./components/Tutorial";
import AuthPage from "./components/AuthPage";
/*import PaymentPage from "./components/PaymentPage";*/
import PaymentPage from "./components/Payment";
import PaymentSuccess from "./components/payment/success";
import PaymentError from "./components/payment/error";

import ForgotPasswordPage from "./components/ForgotPasswordPage";
import ResetPassword from "./components/ResetPassword";
import RegisterSuccess from "./components/RegisterSuccess";
import ForgotPasswordSuccess from "./components/ForgotPasswordSuccess";
import ProcedureInCaseOfDeath from "./components/UsefulLinks/ProcedureInCaseOfDeath";
import TraditionAndPractices from "./components/UsefulLinks/TraditionAndPractices";
import LawOnFund from "./components/UsefulLinks/LawOnFund";
import ProbateProceedings from "./components/UsefulLinks/ProbateProceedings";
import FunrealServices from "./components/UsefulLinks/FunrealServices";
import { API_URL, UMRLICE_LARAVEL_API_TOKEN } from "./config";
import VerifiedByVisaPage from "./components/VerifiedByVisaPage";
import MasterCardSecureCodePage from "./components/MasterCardSecureCodePage";
import ContactInformationPage from "./components/ContactInformationPage";
import AboutUs from "./components/UsefulLinks/AboutUs";
import PaymentTerms from "./components/PaymentTerms";
import PricingList from "./components/UsefulLinks/PricingList";
import InquiriesAndAppeals from "./components/UsefulLinks/InquiriesAndAppeals";
import Cookies from "./components/UsefulLinks/Cookies";
const limit = 10;
//let relatedPosts = [];
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("accessToken")
  );
  const [cities, setCities] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [multiple, setMultiple] = useState(false);
  const [offset, setOffset] = useState(0); // Starting index of posts
  const savedSelectedCity =
    localStorage.getItem("selectedCity") !== null
      ? JSON.parse(localStorage.getItem("selectedCity"))
      : '{ "value": 0, "label": "Izaberi grad" }';
  const [selectedCity, setSelectedCity] = useState(savedSelectedCity);
  const [dateCreated, setDateCreated] = useState("");
  const [ads, setAds] = useState([]);
  const [postData, setPostData] = useState({
    posts: [],
    count: 0,
  });

  const setPosts = (v) => {
    setPostData({
      count: postData.count,
      posts: v,
    });
  };
  const setCount = (v) => {
    setPostData({
      count: v,
      posts: postData.posts,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/ads/`);
        setAds(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    fetchCities();
    fetchLoggedInUserRole();
  }, []);

  // Get the current URL
  let currentURL = window.location.href;

  // Create a URL object from the current URL
  let urlObject = new URL(currentURL);

  // Get the path from the URL object
  let path = urlObject.pathname;

  // Split the path into parts using '/' as the separator
  let pathParts = path.split("/");

  // Remove any empty parts (caused by leading/trailing slashes)
  pathParts = pathParts.filter((part) => part !== "");

  // Get the last part of the path (the endpoint)
  let endpoint = pathParts[pathParts.length - 1];

  useEffect(() => {
    setOffset(0);
  }, [searchQuery, dateCreated]);

  useEffect(() => {
    // Check if the endpoint requires fetchPosts to run
    if (
      !endpoint ||
      endpoint === "umrlice" ||
      endpoint === "pomeni" ||
      endpoint === "poslednji-pozdravi"
    ) {
      // Apply debouncing only for searchQuery changes
      if (searchQuery !== "") {
        const handler = setTimeout(() => {
          fetchPosts(searchQuery);
        }, 500);

        // Cleanup the timeout on dependency change (prevents stacking timers)
        return () => {
          clearTimeout(handler);
        };
      } else {
        // Immediately call fetchPosts for other dependency changes
        fetchPosts(searchQuery);
      }
    }

    // Always scroll to the top on dependency change
    window.scrollTo(0, 0);

    // Dependencies include the necessary states and variables
  }, [offset, searchQuery, selectedCity, dateCreated, endpoint]);

  Date.prototype.mmddyyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      this.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
  };
  const fetchPosts = async (searchQuery = "") => {
    if (!endpoint) {
      endpoint = "/";
    } else if (endpoint === "umrlice") {
      endpoint = "/umrlice";
    } else if (endpoint === "poslednji-pozdravi") {
      endpoint = "/poslednji_pozdravi";
    } else if (endpoint === "pomeni") {
      endpoint = "/pomeni";
    }

    try {
      const response = await axios.get(`${API_URL}/api/posts${endpoint}`, {
        params: {
          limit: limit,
          offset: offset,
          city: selectedCity.value !== 0 ? selectedCity.value : "",
          published_at_after: dateCreated
            ? new Date(
                new Date(dateCreated[0]).setDate(
                  new Date(dateCreated[0]).getDate() + 1
                )
              )
                .toISOString()
                .split("T")[0] + "T00:00:00"
            : "",
          published_at_before: dateCreated
            ? new Date(dateCreated[1]).toISOString().split("T")[0] + "T23:59:00"
            : "",
          ordering: "-published_at",
          search: searchQuery,
          status: "approved",
        },
      });

      setPostData({
        posts: response.data.results,
        count: response.data.count,
      });
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const fetchCities = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/cities`);

      if (cities.length === 0) {
        setCities(response.data);
      }
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setIsLoggedIn(true);
    } else if (!accessToken) {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    const authVerify = () => {
      let decodedJwt;
      let decodedRefresh;
      let refreshToken;
      let token;

      if (
        localStorage.getItem("accessToken") !== null &&
        localStorage.getItem("refreshToken") !== null
      ) {
        token = localStorage.getItem("accessToken");
        refreshToken = localStorage.getItem("refreshToken");
        decodedJwt = parseJwt(token);
        decodedRefresh = parseJwt(refreshToken);
      }

      if (token && refreshToken) {
        if (
          decodedJwt.exp * 1000 < Date.now() &&
          decodedRefresh.exp * 1000 < Date.now()
        ) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          // Update the isLoggedIn state if needed
          setIsLoggedIn(false);
          localStorage.setItem("userRole", "");
          window.location.href = "/";
        } else if (
          decodedJwt.exp * 1000 < Date.now() &&
          decodedRefresh.exp * 1000 < Date.now()
        ) {
          const sendRefresh = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: {
              refresh: refreshToken,
            },
          };
          fetch(`${API_URL}/api/token/refresh/`, sendRefresh).then((response) =>
            response.json()
          );
        }
      }
    };

    authVerify();
  }, []);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const fetchLoggedInUserRole = async () => {
    if (isLoggedIn) {
      try {
        const response = await fetch(`${API_URL}/api/accounts/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const createCart = await fetch(
            `https://umrlice-vijesti-me.com/api/${UMRLICE_LARAVEL_API_TOKEN}/cart/create?user_id=${data.id}`
          );

          localStorage.setItem("userRole", data.type);
          localStorage.setItem("userId", data.id);
          localStorage.setItem("loggedInUserName", data.username);
        } else {
          // Handle unauthorized or error response
          console.log("Error fetching user data:", response.status);
        }
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    } else {
      return;
    }
  };

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );

  const parseJwt = () => {
    let token = localStorage.getItem("accessToken");
    try {
      return JSON.parse(window.atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  return (
    <Router>
      <div className="App" id="App">
        <Header
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
          isLoggedIn={isLoggedIn}
        />

        <Switch>
          <Route exact path="/posts/update/:postId">
            <UpdateForm cities={cities} />
          </Route>
          CON
          <Route exact path="/authorization">
            <AuthPage />
          </Route>
          {isLoggedIn && (
            <Route exact path="/umrlica-qr-form">
              {SubmitForm(
                FormType.Umrlica,
                FormSize.Tiny,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/umrlica-sm-form">
              {SubmitForm(
                FormType.Umrlica,
                FormSize.Small,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/umrlica-md-form">
              {SubmitForm(
                FormType.Umrlica,
                FormSize.Medium,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/umrlica-lg-form">
              {SubmitForm(
                FormType.Umrlica,
                FormSize.Large,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/pp-sm-form">
              {SubmitForm(
                FormType.PoslednjiPozdrav,
                FormSize.Small,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/pp-md-form">
              {SubmitForm(
                FormType.PoslednjiPozdrav,
                FormSize.Medium,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/pp-lg-form">
              {SubmitForm(
                FormType.PoslednjiPozdrav,
                FormSize.Large,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/pp-qr-form">
              {SubmitForm(
                FormType.PoslednjiPozdrav,
                FormSize.Tiny,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/pomen-sm-form">
              {SubmitForm(
                FormType.Pomen,
                FormSize.Small,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/pomen-md-form">
              {SubmitForm(
                FormType.Pomen,
                FormSize.Medium,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/pomen-lg-form">
              {SubmitForm(
                FormType.Pomen,
                FormSize.Large,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          {isLoggedIn && (
            <Route exact path="/pomen-qr-form">
              {SubmitForm(
                FormType.Pomen,
                FormSize.Tiny,
                cities,
                multiple,
                setMultiple
              )}
            </Route>
          )}
          <Route exact path="/posts/:postId" component={SinglePost} />
          <Route exact path="/tutorial" component={Tutorial} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/terms" component={PaymentTerms} />
          <Route exact path="/pricing" component={PricingList} />
          <Route exact path="/upiti-zalbe" component={InquiriesAndAppeals} />
          <Route exact path="/register-success" component={RegisterSuccess} />
          <Route exact path="/cookies" component={Cookies} />
          <Route
            exact
            path="/postupci-u-slucaju-smrti"
            component={ProcedureInCaseOfDeath}
          />
          <Route
            exact
            path="/tradicija-i-obicaji"
            component={TraditionAndPractices}
          />
          <Route
            exact
            path="/ostavinski-postupak"
            component={ProbateProceedings}
          />
          <Route exact path="/pogrebne-usluge" component={FunrealServices} />
          <Route exact path="/pravo-zakona-o-fondu-pio" component={LawOnFund} />
          <Route
            exact
            path="/"
            component={() => (
              <HomePage
                ads={ads}
                isLoggedIn={isLoggedIn}
                cities={cities}
                posts={postData.posts}
                count={postData.count}
                setCount={setCount}
                offset={offset}
                setOffset={setOffset}
                dateCreated={dateCreated}
                setDateCreated={setDateCreated}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                searchQuery={searchQuery}
              />
            )}
          />
          <Route
            path="/umrlice"
            component={() => (
              <Umrlice
                ads={ads}
                isLoggedIn={isLoggedIn}
                cities={cities}
                posts={postData.posts}
                count={postData.count}
                setCount={setCount}
                offset={offset}
                setOffset={setOffset}
                dateCreated={dateCreated}
                setDateCreated={setDateCreated}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                searchQuery={searchQuery}
              />
            )}
          />
          <Route
            path="/predaj-citulju"
            component={() => (
              <PredajCitulju
                setMultiple={setMultiple}
                multiple={multiple}
                isLoggedIn={isLoggedIn}
              />
            )}
          />
          <Route path="/register" component={RegisterPage} />
          <Route
            path="/poslednji-pozdravi"
            component={() => (
              <PoslednjiPozdravi
                ads={ads}
                isLoggedIn={isLoggedIn}
                cities={cities}
                posts={postData.posts}
                count={postData.count}
                setCount={setCount}
                offset={offset}
                setOffset={setOffset}
                dateCreated={dateCreated}
                setDateCreated={setDateCreated}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                searchQuery={searchQuery}
              />
            )}
          />
          <Route
            exact
            path="/pomeni"
            component={() => (
              <Pomeni
                ads={ads}
                cities={cities}
                isLoggedIn={isLoggedIn}
                posts={postData.posts}
                count={postData.count}
                setCount={setCount}
                offset={offset}
                setOffset={setOffset}
                dateCreated={dateCreated}
                setDateCreated={setDateCreated}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                searchQuery={searchQuery}
              />
            )}
          />
          <Route path="/zaboravljena-lozinka" component={ForgotPasswordPage} />
          <Route path="/resetuj-lozinku" component={ResetPassword} />
          <Route path="/login" component={Login} />
          <Route
            path="/contact-information"
            component={ContactInformationPage}
          />
          <Route path="/payment-terms" component={PaymentTerms} />
          <Route path="/verified-by-visa" component={VerifiedByVisaPage} />
          <Route
            path="/mastercard-securecode"
            component={MasterCardSecureCodePage}
          />
          {isLoggedIn ? (
            <Route path="/payment" component={PaymentPage} />
          ) : (
            <Redirect to="/" />
          )}
          {isLoggedIn ? (
            <Route path="/payment-success" component={PaymentSuccess} />
          ) : (
            <Redirect to="/" />
          )}
          {isLoggedIn ? (
            <Route path="/payment-error" component={PaymentError} />
          ) : (
            <Redirect to="/" />
          )}
          <Route
            path="/forgot-password-success"
            component={ForgotPasswordSuccess}
          />
          <Route
            exact
            path="/forma-za-popunjavanje"
            component={FormaZaPopunjavanje}
          />
          {isLoggedIn ? (
            <Route exact path="/redirect-form" component={RedirectForm} />
          ) : (
            <Redirect to="/" />
          )}
          <PrivateRoute
            path="/user-profile"
            component={() => (
              <UserProfile
                ads={ads}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            )}
          />
          <Redirect to="/" />
        </Switch>
      </div>

      <Footer />
    </Router>
  );
}

export default App;
